/* amatic-sc-400normal - latin */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Amatic SC Regular '),
    local('Amatic SC-Regular'),
    url('./files/amatic-sc-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/amatic-sc-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* amatic-sc-700normal - latin */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Amatic SC Bold '),
    local('Amatic SC-Bold'),
    url('./files/amatic-sc-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/amatic-sc-latin-700.woff') format('woff'); /* Modern Browsers */
}

